import React from 'react';
import Navi from '../nav';
import Footer from '../footer';
import { Link } from 'react-router-dom';
const Instrument = () =>
    <div>
        <Navi selected="weight" />
        <div className="lead">
            You want to get better you need to play every single day. You already know this. So do it:
            <ul>
                <li>Set the same time every day when you will practice.</li>
                <li>Consider that you can probably practice way more than you are right now and that others might be. </li>
                <li>Commit to something that works for you.</li>
                <li>Focus on the pieces that you are having trouble with.</li>
                <li>Talk with the Accountable Stranger every day to checkin.</li>
            </ul>
            For more inspiration we recommend <Link to="https://kennywerner.com/effortless-mastery">Effortless Mastery</Link> This great book gives you more advice on how to practice.
        </div>
        <Footer />
    </div>

export default Instrument;