import React from 'react';
import Navi from '../nav';
import Footer from '../footer';
const Weight = () =>
    <div>
        <Navi selected="goals" />
        <h2>Weight Loss</h2>
        <img src="/images/goals/weight/weight03.jpg" alt="scale" width="300px" className="mx-auto d-block" />
        <div className="lead">
            The secret to weight loss (like nearly everything else) is consistency.  You don’t need a special diet and you don’t need to go to a gym everyday.
            <br />
            Losing weight is simply a function of expending more calories in a day than you consume. But like most things, you won’t see any difference unless you do it consistently over time.
            <br />
            Here is how our weight loss plan works:
            <ol>
                <li>Weigh yourself everyday.</li>
                <li>Track every calorie that you eat and stay within your calorie goals. (You can find calorie goal&nbsp;
                    <a href="https://www.mayoclinic.org/healthy-lifestyle/weight-loss/in-depth/calorie-calculator/itt-20402304">here</a> or other places on the internet.)</li>
                <li>Track activity: work towards 10,000 steps a day.</li>
                <li>Plan intermitent fasting certain times each week.</li>
                <li>Check in with your stranger 5 minutes every day</li>
            </ol>
            <img src="/images/goals/weight/weight01.jpg" alt="scale" width="300px" className="mx-auto d-block" />
            <h3>Accountability Questions:</h3>
            The stranger will ask you the following:
            <ul>
                <li>Did you weigh yourself today and what was your weight?</li>
                <li>What smart choices did you make in the foods you ate? What were some bad choices?  What are you going to do next time?</li>
                <li>Did you track your calories today and did you stay within your calorie goal?</li>
                <li>How many steps did you get?</li>
                <li>Are you going to do intermitent fasting?  When?  How long?</li>
            </ul>
            As everyone is different each time the questions will be taylored to you.
            <br />
            <br />
            <h3>How Does the Accountable Stranger Compare to other Weight Loss Options?</h3>
            With the accountable stranger it’s not all or nothing.  You can mix or disregard other options that might help you reach your goals.
            Just remember:  What you decide doesn’t matter.  All that matters is consistency and accountability.
            <br />
            <br />
            <h4>Gym Memberships</h4>
            <br />
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Gym</th>
                        <th scope="col">Costs</th>
                        <th scope="col">Our Take</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>24 Hour Fitness</th>
                        <td>$20-$50/month</td>
                        <td>
                            Going to the gym is a good goal.  The Accountable Stranger can compliment this.
                            But sometimes going for a walk is all you need to use more calories
                        </td>
                    </tr>
                    <tr>
                        <th>Planet Fitness</th>
                        <td>$10-$25/month plus $50-$70 in startup+annual fees.</td>
                        <td>
                            We like the gym but the Accountable Stranger is more effective to helping you go if you say you’re going to go.
                        </td>
                    </tr>
                    <tr>
                        <th>Orange Theory</th>
                        <td>$70-$179/month</td>
                        <td>
                            ehen you’re paying this much money a month you’ll hopefully go cause you’ll feel guilty wasting money for classes you don’t attend.
                            Still, the Accountable Stranger is someone to answer to to ensure you go.
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <h4>Wellness Apps for Weight Loss</h4>
            <br />
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">App</th>
                        <th scope="col">Costs</th>
                        <th scope="col">Our Take</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>MyFitnessPal</th>
                        <td>Free with Ads, or $10/month or $50/year</td>
                        <td>
                            We don't like ads but we like the tracking capability of this app. It works very well with the stranger. We've learned that if you're not being
                            held accountable it's hard to stick to it.  However the app does have annoying reminders to help you in addition to your daily checkins with us.
                        </td>
                    </tr>
                    <tr>
                        <th>Noom</th>
                        <td>$169 for 4 months or $70/month</td>
                        <td>
                            Noom has classes that educate you on food habits and the psychology of food.
                            It’s easy to get into but we found the app buggy in tracking calorie goals and the personal coaches were not even a thing.
                            No help there. There are meal plans that seem to be customized but we didn’t use any of them.
                            If you’re going to go with Noom we don’t recommend you do it with the Accountable Stranger as Noom tries to do it all.
                            We've heard many people have success with this app.  We don't recommend it, but it still might work for you!
                        </td>
                    </tr>
                    <tr>
                        <th>MyBodyTutor</th>
                        <td>$299-$599/month</td>
                        <td>
                            This app gives you a real person like the accountable stranger who calls you every day and keeps you accountable.  We love this!
                            We just have a hard time with the price. If this is in your budget we’d recommend this route instead of the Accountable Stranger if weight is your only goal.
                            Here's why:
                            <ul>
                                <li>Real dietician and fitness coaches.  The accountable stranger assumes you know what you want.  If you don't know what you want, these guys might be able to help you. </li>
                                <li>Meal plans and someone like the Accountable stranger that calls you.  (Just like we do, so you probably don't need both)</li>
                            </ul>
                            It may be worth using this service for a month and when you figure it out, come back to us, and we'll help you keep your healthy habits going.
                        </td>
                    </tr>
                </tbody>
            </table>
            <img src="/images/goals/weight/weight02.jpg" alt="scale" width="200px" className="mx-auto d-block" />




        </div>
        <Footer />
    </div>

export default Weight;