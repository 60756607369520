import React from 'react';
import Navi from './nav';
import { InlineWidget } from 'react-calendly';
const Schedule = () =>
    <div>
        <Navi selected="schedule" />
        <ol className="lead">
            <li>Schedule the day of your first call. No credit card required.</li>
            <li>Sign up via the calendly link below.</li>
            <li>The first week is free</li>
            <li>After week 1, you will be prompted to pay <span className="text-success font-weight-bold">$65/month</span></li>
            <li>Someone will call you Monday-Friday for 5 minutes at the time you specify</li>
            <li>You will acheive great results by seeing the power of accountability and consistency.</li>
        </ol>
        <InlineWidget url="https://calendly.com/accountablestranger/accountable-call" />
    </div>

export default Schedule;