import React from 'react';
import Navi from '../nav';
import Footer from '../footer';
const Time = () =>
    <div>
        <Navi selected="goals" />
        <div className="lead">
            Let's face it, we're all addicted to our screens.  The people in big tech know how to do their jobs extremely well and as a result we're all being
            entertained to death in this Brave New World.
            <br />
            <br />
            What can you do?
            <br />
            <br />
            <ul>
                <li>Take a social media Fast.  Try deleting TikTok, Instagram, SnapChat, Facebook or whatever you're using.</li>
                <li>Take a break from entertainmnet.  Delete YouTube, Netflix, DisneyPlus, HBOMAX, or whatever else you're using.</li>
                <li>Once the fast is over, set up time limits.</li>
                <li>Use app measurement tools like ScreenTime to see how long you are using it and putting it below</li>
                <li>Have your daily 5 minute checkin with the stranger.</li>
            </ul>
            <br />
            <br />
            The most important rule I look at is this: When I'm on my screen am I producing or consuming?  That ratio should be over 50% producing if you are going
            to take your life to the next level.
        </div>
        <Footer />
    </div>

export default Time;