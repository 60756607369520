import React from 'react';
import Footer from './footer';
import Navi from './nav';

let qa = {
    "What is a stranger?": "The stranger is a micro-coach. They are good people that want to help you achieve your goals. Small consistent coaching makes you a better person.",
    "Who is the stranger?": "The stranger is a person affiliated with Accountable Stranger. We vet each person.  They have been trained and most important will be consistent.",
    "Will I get the same person every time?": "Our intention is that you will develop a relationship with your own personal stranger.  Think of the stranger as a real live person on the internet who is routing for you.  We make every effort to keep the same person calling you every day.",
    "What is the commitment term?": "You pay upfront for a month of weekly checkins. There is no commitment to renew.  The first week is free. We guarantee you will see positive results.  If you are not satisfied for any reason we will give you a full refund.",
    "Where are these strangers located?": "All of the strangers are based in the United States where English is their first language.",
    "How does this program work?": "You set a goal and ask the personal stranger to checkup on these goals.  Every day they will call you and ask how you've done reaching this goal. You need to specify which questions you want the stranger to ask. Based on the strangers personal experience they may ask you additional questions with the intention of helping you reach your goal."
};

function FAQ() {
    return (
        <div >
            <Navi selected="faq" />
            {Object.keys(qa).map((question, i) => (
                <div className="mt-2 lead" key={i}>
                    <b>Q.</b> {question}
                    <br />
                    <b>A.</b> {qa[question]}
                </div>
            ))}
            <Footer />
        </div>
    )
}
export default FAQ;