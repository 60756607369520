import React from "react";
import Emoji from "./emoji";

const Footer = () => {
    return (
        <footer className="pt-4 mt-md-5 pt-md-5 footer border-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>Accountable Stranger</h4>
                        <p>
                            Accountable Stranger is a new service where a person checks in with you daily to help you reach your goals.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h4>Links</h4>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/about">About</a>
                            </li>
                            <li>
                                <a href="/privacy">Privacy</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4>Contact</h4>
                        Made with <Emoji emoji="💚" label="love" /> in <Emoji emoji="🏰" label="castle" /> <Emoji emoji="🪨" label="rock" />
                        <br />
                        415 Zane Ln<br />
                        Castle Rock, WA<br />
                        98611
                        <br />
                        Text any time: <b>(714) 841-0999</b>
                    </div>
                </div>
            </div>
        </footer >
    );
}

export default Footer;