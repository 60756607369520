import React from 'react';
import Navi from '../nav';
import Footer from '../footer';
import { Link } from 'react-router-dom';
const Spiritual = () =>
    <div>
        <Navi selected="goals" />
        <div className="lead">
            The most noble of goals isn't to improve the outward appearance but to improve the inward vessel.  It is by improving yourself spiritually
            all other goals will fall into place.
            To become more spiritual, do the following:
            <ul>
                <li>Pray and meditate everyday at least 5-15 minutes</li>
                <li>Read inspiring words.  This can be scriptures or works of other holy people.</li>
                <li>Serve.  Look for volunteer opportunities in your community. You don't need to start a charity, you can just help one person.</li>
                <li>Speak with the stranger every day to ensure you are reaching your goals.</li>
            </ul>
            Oftentimes, you can combine goals with Spiritual goals.  Suppose you are looking to start a business: You can keep grounded spiritually while
            reaching your worldly goals.
        </div>
        <Footer />
    </div>

export default Spiritual;