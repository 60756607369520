import React from 'react';
import Footer from './footer';
import Navi from './nav';
import { Link } from 'react-router-dom';

const About = () =>
    <div>
        <Navi selected="about" />
        <div className="lead">
            My name is Vallard and I started Accountable Stranger in 2023 to be the service I was looking for to hit my goals. I hope it's the service that will let you
            hit your goals as well.  This service is a new category called: 'micro-coaching'. It's relentless pressure gently applied to help you reach your goals.
            <br />
            <br />
            During my research I recognized that the most effective way to accomplish my goals was
            to have someone hold me accountable for my actions.  I had read many blog articles of people hiring others to just watch
            them work and how they would see their productivity increase 1000%.
            <br />
            I soon realized that succeess is built on two key elements:
            <br />
            <br />
            <ol>
                <li>Accountability</li>
                <li>Consistency</li>
            </ol>
            <img src="images/success-model.png" alt="consistency-drives-success" />
            <br />
            Think about any goal that you ever accoumplished in your life:  It was cause you were accountable to someone and you were
            consistent.  Accountability is one of the biggest drivers of consistency, and consistency is the biggest driver of success.
            <br />
            <br />
            For some of us it may be easy to be consistent in one area of our life but difficult in others.  I realized that by hiring someone to hold you
            accountable is the best way to keep you consistent.
            <br />
            <br />
            But hiring a personal coach, a personal tutor, or a personal trainer is expensive. If you look at many successful people you'll find
            they hire all kinds of people to help them reach their goals.
            <br />
            <br />
            So how can you get access to these successful tools if you're still a success story waiting to happen? That's where we come in.
            <br />
            <br />
            For about $2/day Mon-Fri we will call you and talk to you for 5 minutes about what you're working on.  So if you're trying to lose weight, and you're about to
            eat that second chocolate cake, you'll think about:  Oh wait a minute, Vallard is gonna be asking me about this tomorrow. See? Just one simple
            change of accountability can help you make better decisions.
            <br />
            <br />
            So if you haven't yet, <Link to="/schedule">give it a try</Link> The first week is free and you have nothing to lose. If you want to keep with
            the program it's about $2/day.  Probably the best $2 each day you'll spend - Cause you're building the future you.
            <br />
            <br />
            Thank you!
            <br />
            Vallard Benincosa
            <p>Text me if you have any other questions: (714) 841-0999</p>
        </div>
        <Footer />
    </div>

export default About;