import React from "react";
import { Route, Switch } from 'react-router-dom';

import Home from '../components/home';
import Schedule from "../components/schedule";
import NotFound from '../components/notfound';
import About from '../components/about';
import Weight from '../components/goals/weight'
import Novel from '../components/goals/novel';
import Instrument from '../components/goals/instrument';
import Spiritual from '../components/goals/spiritual';
import Time from '../components/goals/time';
import FAQ from '../components/faq';
import Privacy from "../components/privacy";
import Books from "../components/books";

const allRoutes = () =>
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/schedule" component={Schedule} />
        <Route exact path="/about" component={About} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/goals/weight" component={Weight} />
        <Route exact path="/goals/write" component={Novel} />
        <Route exact path="/goals/play" component={Instrument} />
        <Route exact path="/goals/spiritual" component={Spiritual} />
        <Route exact path="/goals/time" component={Time} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/books" component={Books} />
        <Route component={NotFound} />
    </Switch>

export default allRoutes