import React from 'react';
import Navi from './nav';
import Footer from './footer';
import { Link } from 'react-router-dom';

const bookList = [
    {
        title: "168 Hours",
        author: "Laura Vanderkam",
        image: "https://lauravanderkam.com/wp-content/uploads/2018/03/168_hours_book_pic-1-668x1024.jpg",
        url: "https://lauravanderkam.com/books/168-hours/",
        take: "This book shows us we can really accomplish a lot more than we are doing.  We have more time than we think!"
    },
    {
        title: "Big Magic",
        author: "Elizabeth Gilbert",
        image: "https://www.elizabethgilbert.com/wp-content/uploads/2018/09/bigmagic-cover.jpg",
        url: "https://www.elizabethgilbert.com/books/big-magic/",
        take: "We love how this book explores creativity and inspiration.  Her prose is very well written and enjoyable to read."
    },
    {
        title: "How Will You Measure Your Life?",
        author: "Clayton M. Christensen",
        image: "https://claytonchristensen.com/wp-content/uploads/2012/07/featured1.jpg",
        url: "https://claytonchristensen.com/books/how-will-you-measure-your-life/",
        take: "Some questions to ask yourself to make you a better person.  We especially loved the 100% is easier than 98% rule."

    }
]




const Books = () =>
    <div>
        <Navi selected="books" />
        <div className="lead">
            We've been inspired by a few great books that help us with creativity, time management, and in general just reaching our goals.
            We are not affiliated with these books or authors in any way, but recommend them based on our experiences. These are all books I've read in 2023.
            {bookList.map((book, index) => (
                <div className="card my-5" key={index}>
                    <div className="row">
                        <div className="col-md-2">
                            <img src={book.image} height="200px" width="150px" alt={book.title} />
                        </div>
                        <div className="col-md-10">
                            <div className="card-body">
                                <h5 className="card-title">{book.title}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{book.author}</h6>
                                <p className="card-text">{book.take}</p>
                                <br />
                                <Link to={book.url}>Author's Website</Link>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </div>
        <Footer />
    </div>

export default Books;