import React from 'react';
import Navi from '../nav';
import Footer from '../footer';
import { Link } from 'react-router-dom';
const Novel = () =>
    <div>
        <Navi selected="goals" />
        <div className="lead">
            Are you looking to finish that book? Then you need to commit to writing!
            <br />
            <br />
            Every published author will tell you:  You just need to sit down and do the work!
            <br />
            <br />
            Of course they all have their "secrets" but those secrets amount to simple things like:
            <br />
            <br />
            <ul>
                <li>Commit to <Link to="https://ryanlanz.com/2015/08/12/how-to-write-5000-words-a-day/">writing 5,000 words a day</Link></li>
                <li>Find a time and commit to nothing else but writing that same time every day.</li>
                <li>Find a place where you can feel creative.  <Link to="https://youtu.be/dIRI4F-KKSo">Roald Dahl liked his little shop in his backyard.</Link></li>
            </ul>
            You'll have many excuses why you haven't done it yet, or reasons to think, I need x y and z done first before I embark on this incredible idea
            but the truth is, you don't need any of that.  You just need to start.  Decide.  Make the decision and do it.
            <br />
            <br />
            Once you've decided and you ask the stranger for help your call will look something like this:
            <br />
            <br />
            <b>Stranger: </b> Hello!  How many words did you write yesterday?
            <br /><br />
            <b>You: </b> I knew you were going to ask me that, so I wrote about 3,000.
            <br /><br />
            <b>Stranger: </b> What were your ideas and what did you write on?
            <br /><br />
            <b>You: </b> I wrote about a girl growing up in Japan who wants to race motorcycles with her brother but gets into a horrible accident and has to learn to walk again first.
            <br /><br />
            <b>Stranger: </b> That's great, what is the next part going to be about?
            <br /><br />
            <b>You: </b> I'm not sure, but I'm thinking it may be she goes to the US and attends school and finds inspiration to try again.
            <br /><br />
            <b>Stranger: </b> When will you write tomorrow?
            <br /><br />
            <b>You: </b> Same time as I did today, after the kids are at school from 10am - 11:30am.
            <br /><br />
            <b>Stranger: </b> Awesome.  I can't wait to hear about the next part.  This book sounds promising.
            <br /><br />
            <b>You: </b> Thank you!  I'll talk to you tomorrow!
            <br /><br />

        </div>
        <Footer />
    </div>

export default Novel