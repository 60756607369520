import React from 'react';
import Footer from './footer';
import Navi from './nav';
const Privacy = () =>
    <div>
        <Navi selected="privacy" />
        <div className="lead">
            We don't sell your data.  We keep it private.  We don't use Google or Facebook Ad trackers or pixels on our website.
            We do, however, sell ads on Google in order to let people know about our product. However, we do no other tracking.
            <br />
            We basically follow the golden rule of data privacy:  I treat your data the way I would want somebody to treat my data.
            <br />
            Our 5 min daily sessions are private, we may keep notes to help serve you better but we don't store them online and keep
            what you say confidential.
            <br />
        </div>
        <Footer />
    </div>

export default Privacy;