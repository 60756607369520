import React from 'react';
import { Link } from 'react-router-dom';

function UseCaseDropDown() {
    return (
        <li className="nav-item dropdown">
            <button className="btn nav-link dropdown-toggle" id="userDropDownMenu"
                data-bs-toggle="dropdown" aria-expanded="false" aria-label="Toggle navigation">
                Use Cases
            </button>
            <ul className="dropdown-menu" aria-labelledby="userDropdownMenu">
                <li><Link to="/goals/weight" className="dropdown-item">Weight Loss</Link></li>
                <li><Link to="/goals/write" className="dropdown-item">Write</Link></li>
                <li><Link to="/goals/play" className="dropdown-item">Play Music</Link></li>
                <li><Link to="/goals/spiritual" className="dropdown-item">Spiritual</Link></li>
                <li><Link to="/goals/time" className="dropdown-item">Time Management</Link></li>
            </ul>
        </li>
    )
};

function Navi({ selected, error, message }) {
    return (
        <>
            <nav className="navbar navbar-expand-md navbar-light" style={{ 'backgroundColor': '#fff' }}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src="/images/as-logo.png" alt="Accountable Stranger" height="100px" className="d-inline-block align-text-top" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav ml-auto mb-2 mb-lg-0 d-flex">
                            <li className="nav-item">
                                <Link to="/schedule" className={selected === "schedule" ? "nav-link active" : "nav-link"} >Schedule Now.</Link>
                            </li>
                            <li>
                                <Link to="/about" className={selected === "about" ? "nav-link active" : "nav-link"} > About Us.</Link>
                            </li>
                            <li>
                                <Link to="/faq" className={selected === "faq" ? "nav-link active" : "nav-link"} >FAQs.</Link>
                            </li>
                            <UseCaseDropDown selected={selected} />
                            <li>
                                <Link to="/books" className={selected === "books" ? "nav-link active" : "nav-link"} >Books.</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav >
            {
                message &&
                <div className="alert alert-success text-center">
                    {message.toString()}
                </div>
            }
        </>
    )
}

export default Navi;