import React from 'react';
import Navi from './nav';
import Emoji from './emoji';
import { Link } from 'react-router-dom';
import Footer from './footer';
import Carousel from './carousel'

const words = [
    { text: "Take Your Life to the Next Level.", subtext: "Spend $2 a day to boost your productivity 1000%", image: "/images/rocket.jpg" },
    { text: "Own your biggest goals.", subtext: "Get your accountability coach to rocket your life.", image: "/images/dad-helping.jpg" },
]


function Home() {
    return (
        <div>
            <Navi message="Try it free for one week! No Credit Card required!" />

            <Carousel words={words} />

            <h1 className="mt-5">What is the Accountable Stranger?</h1>
            <p className="lead">
                The Accountable Stranger is a coach you hire to do a daily 5 minute checkin Monday-Friday.
                Their job is to hold you accountable to reach your biggest goals.
            </p>
            <h2>How it works</h2>
            <ul className="lead">
                <li><Emoji emoji="📆" label="schedule" /> Schedule a time with a stranger <Link to="/schedule">here.</Link> The first week is free.</li>
                <li><Emoji emoji="📲" label="call" /> You will get a real live voice call daily at that time that takes 5 minutes.</li>
                <li><Emoji emoji="🎯" label="goals" /> Go over your goals and be accountable to the stranger.</li>
                <li><Emoji emoji="⏳" label="watch" /> Watch as your goals take shape and your productivity increases 1000%</li>
            </ul>
            <div className="text-center">
                <Link to="/schedule" className="btn btn-primary btn-lg">Schedule Now</Link>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
